.App{
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  background-color: #f9fafc;
}

.BodyPart{
  background-color: #f9fafc;
}

body {
  margin: 0px;
  padding-top: 0px;
}

.Sidebar{
  display: flex;
  height: auto;
  width: 21vw;
  flex-direction: column;
  background-color: rgb(17,24,39);
  position: sticky;
}

.SidebarList{
  height: 100%;
  width: 100%;
  padding: 0px;
}

.SidebarList .row{
  display: flex;
  height: 40px;
  width: 20vw;
  list-style-type: none;
  padding: 0px;
  flex-direction: row;
  color: rgb(203,204,213);
  font-size: 15px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.row #icon{
  flex: 30%;
  display: grid;
  place-items: end;;
  
}

.row #title{
  flex:70%;
  margin:10px;

}


.SidebarList .row:hover{
  cursor: pointer;
  border-radius: 5px;
  background-color: rgb(36,42,56);
}


.SidebarList .Category{
  margin-left: 50px;
  height: 20px;
  width: 20vw;
  color: rgb(99,104,122);
  font-weight: bolder;
  font-size: small;
}

.Headbar{
  display: flex;
  height: 200px;
  width: 21vw;
  flex-direction: column;
  border-bottom: 0.1pt solid;
  color:rgb(54, 54, 54);
  background-color: rgb(17,24,39);
}


.premium{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(26,33,47);
  width: 250px;
  height:75px;
  margin:20px;
  border-radius: 10px;
}

#Acme{
  margin-top: 20px;
  color: white;
  width: 100px;
  height:5px;
  display: grid;
  font-weight: bold;
  margin-left: 20px;
}

#Tier{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  color: rgb(229, 231, 240);
  margin:20px;
}

.logo{
  margin-top: 22px;
  margin-left: 27px;
}

.Footbar{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 150px;
  width: 21vw;
  border-top: 0.1px solid white;
  background-color: rgb(17,24,39);
}

p{
  color: white;
  margin:0;
}

#button{
  margin-left: 20px;
}
#text2{
color: rgb(203,204,213);
}


.Navbar{
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  width: 1050px;
  height:55px;
  background-color:white;
}

.Navbar div{
  margin: 10px 20px 10px 10px;
}



.Part1{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Part1 .Part1Flex{
  display: flex;
  flex-direction: row;
  width: 300px;
  height:130px;
  justify-content: space-evenly;
  align-items: flex-end;

}

.Part1 #Report{
  width:150px;
  border-radius: 10px;
  border: 1px solid blue;
}

.Part1 #LastWeek{
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid rgb(155, 155, 161);
}

.Part1Flex div{
  margin: 10px;
}

h1{
  margin-left: 15px;
  margin-top: 80px;
}

#text{
  color: blue;
}

.Part2{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px;
  width:1000px;
  height: 250px;
  border-radius: 10px;
  background-color: rgb(80,72,229);

}

#image{
  margin: -8px;
}

.Part2Content div{
  height:30px;
  color:white;
  font-weight: 500;
  margin-top: 20px;
  margin-left: 20px;
}

.Part2Content div#Part2ContentText2{
  font-size: large;
}

.Part2Content h1{
  margin: 0;
}
#Part2ContentText1{
  width: 50px;
  border: 0.1px solid rgb(16,185,129);
  overflow: hidden;
  justify-content: center;
  border-radius: 100px;
  background-color: rgb(16,185,129);
}

#Part2ContentText1 p{
  margin-top: 5px;
  margin-left:10px;
  margin-bottom: 5px;
  font-size: small;
}

.Part3{
  display: flex;
  flex-direction: row;
}

.Card1{
  margin-top: 20px;
  margin-left: 20px;
}

.Card2{
  margin-top: 20px;
  margin-left: 30px;
}

.Part5{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#Part51{
  margin-top: 20px;
  margin-left: 20px;
}

#Part52{
  margin-top: 20px;
  margin-left: 30px;
}
 
#Part53{
  margin-top: 30px;
  margin-left: 20px;
}

#Part54{
  margin-top: 30px;
  margin-left: 30px;
}
